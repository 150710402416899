<template>
  <div class="border rounded p-5 relative bg-white shadow-sm">
    <div class="bg-gray-100 h-14" style="margin: -20px"></div>
    <div class="text-center" style="margin-top: -32px">
      <img :src="userAviator" class="w-16 h-16 rounded-full inline-block" />
      <div class="mt-3">
        <span class="text-primary font-normal leading-relaxed">{{
          user.username
        }}</span>
      </div>
    </div>
    <el-menu
      style="margin: 0 -20px; margin-right: -21px"
      :default-active="defaultActive">
      <el-menu-item class="leading-10 h-10 panel-item" index="/">
        <router-link to="/">
          <i class="el-icon-s-home mr-3"></i>首页
        </router-link>
      </el-menu-item>
      <el-menu-item class="leading-10 h-10 panel-item" index="/basic/info">
        <router-link to="/basic/info">
          <i class="el-icon-info mr-3"></i>个人资料
        </router-link>
      </el-menu-item>
      <el-menu-item class="leading-10 h-10 panel-item" index="/change/password">
        <router-link to="/change/password">
          <i class="el-icon-setting mr-3"></i>修改密码
        </router-link>
      </el-menu-item>
      <el-menu-item class="leading-10 h-10 panel-item" @click="onLogout">
        <i class="el-icon-switch-button mr-3"></i>退出登录
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import environment from "@/environment";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "UserPannel",
  computed: {
    ...mapGetters("user", ["user"]),
    userAviator() {
      return this.getImageUrl(this.user?.url);
    },
    defaultActive() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions("user", ["logout"]),
    getImageUrl(url) {
      return url ? environment.downloadHost + url : "";
    },
    //退出登录
    onLogout() {
      this.logout().then(() => {
        this.$router.push({
          name: "login"
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-item {
  height: 40px;
  line-height: 40px;
}
</style>
