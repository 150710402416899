module.exports = [
  // {
  //   meta: {
  //     title: "ebaobei"
  //   },
  //   children: [
  //     {
  //       path: "/ebb/products",
  //       name: "ebb-products",
  //       meta: {
  //         title: "产品投保",
  //         icon: "el-icon-s-goods"
  //       }
  //     }
  //   ]
  // },
  {
    meta: {
      title: "jinbaobao"
    },
    children: [
      {
        path: "/jbb/products",
        name: "jbb-product",
        meta: {
          title: "产品投保",
          icon: "el-icon-s-goods"
        }
      }
    ]
  }
];
