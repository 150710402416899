<template>
  <div class="flex flex-row max-w-screen-xl m-auto">
    <div class="flex-shrink-0" style="width: 210px; margin-right: 20px">
      <!-- <user-pannel class="mb-5" /> -->
      <user-pannel />
      <el-menu class="shadow-sm rounded mt-5" :default-active="defaultIndex">
        <template v-for="(item, index) in router">
          <el-menu-item-group
            v-if="item.children && allTrue(item.rules)"
            :key="index"
            :title="item.meta.title"
          >
            <el-menu-item
              v-for="(item1, index1) in item.children"
              :key="index + '-' + index1"
              :index="item1.name"
              @click="
                item1.onClick ? item1.onClick(_self) : $router.push(item1.path)
              "
            >
              <i :class="item1.meta.icon"></i>
              {{ item1.meta.title }}
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item
            v-else-if="allTrue(item.rules)"
            :key="index"
            :index="item.name"
            @click="
              item.onClick ? item.onClick(_self) : $router.push(item.path)
            "
            >{{ item.meta.title }}</el-menu-item
          >
          <span v-else :key="index" class="hidden" />
        </template>
      </el-menu>
    </div>
    <div class="flex-1 shadow-sm rounded bg-white p-5 overflow-x-auto">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import router from "./router";
import { mapGetters } from "vuex";
import UserPannel from "@/components/user-pannel/UserPannel";
export default {
  components: {
    UserPannel
  },
  data() {
    return {
      router,
      key: new Date().getTime()
    };
  },
  watch: {
    $route(v) {
      this.key = v.query.templateId;
    }
  },
  computed: {
    ...mapGetters("user", ["memberId"]),
    defaultIndex() {
      return this.$route.name;
    }
  },
  methods: {
    allTrue(rules) {
      if (!rules) return true;
      if (Array.isArray(rules)) {
        return rules.reduce((b, a) => a(this) && b, false);
      }
      return rules(this);
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => this.$router.push("/"));
    }
  }
};
</script>
